<template>
	<header class="header-main fixed-top header-dark shadow-sm">
		<nav class="navbar navbar-expand-lg z-index-3 py-2 navbar-dark">
			<div class="container">
				<a class="navbar-brand" href="/#/">
					<img class="logo-light" src="/static/img/wappen.png" height="50px" width="45px">
					<img class="logo-dark" src="/static/img/wappen.png" height="50px" width="45px">
				</a>
				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav m-auto">
						<li class="nav-item">
							<router-link :to="{ name: 'Home' }" class="nav-link">Home</router-link>
						</li>
						<li class="dropdown nav-item">
							<router-link :to="{ name: 'Verein' }" class="nav-link">Verein</router-link>
							<label class="px-dropdown-toggle mob-menu"></label>
							<ul class="dropdown-menu left list-unstyled">
								<li><router-link :to="{ name: 'VereinNews' }" class="dropdown-item">Neuigkeiten</router-link></li>
								<li><router-link :to="{ name: 'VereinAbout'}" class="dropdown-item">Über uns</router-link></li>
								<li><router-link :to="{ name: 'VereinMembers', params: { type: 'vorstand' } }" class="dropdown-item">Vorstand</router-link></li>
								<li><router-link :to="{ name: 'VereinMembers', params: { type: 'beisitzer' } }" class="dropdown-item">Erweiterter Vorstand</router-link></li>
								<li><router-link :to="{ name: 'Beitreten' }" class="dropdown-item">Mitglied werden</router-link></li>
							</ul>
						</li>
						<li class="dropdown nav-item">
							<router-link :to="{ name: 'Arbeitsgruppen' }" class="nav-link">Arbeitsgruppen</router-link>
							<label class="px-dropdown-toggle mob-menu"></label>
							<ul class="dropdown-menu left list-unstyled">
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'backakademie' } }" class="dropdown-item">Backakademie</router-link></li>
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'banater-knipser' } }" class="dropdown-item">Banater Knipser</router-link></li>
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'veranstaltungsdekoration' } }" class="dropdown-item">Veranstaltungsdekoration</router-link></li>
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'theatergruppe' } }" class="dropdown-item">Theatergruppe</router-link></li>
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'trachtengruppe' } }" class="dropdown-item">Trachtengruppe</router-link></li>
							</ul>
						</li>
						<li class="dropdown nav-item">
							<router-link :to="{ name: 'Veranstaltungen' }" class="nav-link">Veranstaltungen</router-link>
							<label class="px-dropdown-toggle mob-menu"></label>
							<ul class="dropdown-menu left list-unstyled">
								<li><router-link :to="{ name: 'Rueckblicke' }" class="dropdown-item">Rückblicke</router-link></li>
								<li><a class="dropdown-item" href="/#/veranstaltungen/in/reutlingen">in Reutlingen</a></li>
								<li><a class="dropdown-item" href="/#/veranstaltungen/in/deutschland">in Deutschland</a></li>
								<li><a class="dropdown-item" href="/#/veranstaltungen/in/rumaenien">in Rumänien</a></li>
							</ul>
						</li>
						<li class="dropdown nav-item">
							<router-link :to="{ name: 'Kontakt' }" class="nav-link">Kontakt</router-link>
							<label class="px-dropdown-toggle mob-menu"></label>
							<ul class="dropdown-menu left list-unstyled">
								<li><router-link :to="{ name: 'Kontakt' }" class="dropdown-item">Kontakt</router-link></li>
								<li><router-link :to="{ name: 'Impressum' }" class="dropdown-item">Impressum</router-link></li>
								<li><router-link :to="{ name: 'Datenschutz' }" class="dropdown-item">Datenschutz</router-link></li>
								<li><router-link :to="{ name: 'Spenden' }" class="dropdown-item">Spenden</router-link></li>
								<li><router-link :to="{ name: 'Beitreten' }" class="dropdown-item">Mitglied werden</router-link></li>
							</ul>
						</li>
					</ul>
				</div>
				<div class="nav justify-content-end dark-link flex-nowrap">
					<button class="navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
				</div>
			</div>
		</nav>
	</header>
</template>