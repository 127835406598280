<template>
	<section v-if="error">
		<div class="m-5 alert alert-danger" role="alert">
			Failed to get backend data :(
		</div>
	</section>
	<section v-else-if="loading">
		<div class="m-5 alert alert-info" role="alert">
			Preloading page, please wait...
		</div>
	</section>

		<SiteHeader />
		<router-view />
		<SiteFooter />

</template>
<script>
import SiteHeader from '@/views/SiteHeader.vue'
import SiteFooter from '@/views/SiteFooter.vue'
export default {
	components: {
		SiteHeader,
		SiteFooter,
	},
	computed: {
		error() {
			return this.$store.state.errorStatus;
		},
		loading() {
			return this.$store.state.loadingStatus;
		}
	},
}
</script>