import * as Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import store from './store'

import 'bootstrap/js/dist/carousel';

moment.locale("de")
const app = Vue.createApp(App).use(store)
app.use(router, axios, moment).mount('#app')
axios.defaults.baseURL = atob('aHR0cHM6Ly9hcGkuYnMtcnQuZGUv');
axios.defaults.headers.common['Authorization'] = atob("QmVhcmVyIDUxYzU2YjVmLTNhZTUtNDkzNy1iNmRhLTFiM2Y5ZWY4YmVkZg==");