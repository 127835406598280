import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/components/Home.vue')
	},
	{
		path: '/verein',
		name: 'Verein',
		component: () => import ('@/views/NewsList.vue')
	},
	{
		path: '/verein/ueberuns',
		name: 'VereinAbout',
		component: () => import('@/views/About.vue')
	},
	{
		path: '/verein/mitglieder/:type',
		name: 'VereinMembers',
		component: () => import('@/components/Members.vue'),
		params: true
	},
	{
		path: '/verein/neuigkeiten',
		name: 'VereinNews',
		component: () => import('@/views/NewsList.vue')
	},
	{
		path: '/arbeitsgruppen',
		name: 'Arbeitsgruppen',
		component: () => import('@/components/Groups.vue')
	},
	{
		path: '/arbeitsgruppe/:slug',
		name: 'Arbeitsgruppe',
		component: () => import('@/components/Group.vue'),
		props: true,
	},
	{
		path: '/mitglied/:id',
		name: 'Mitglied',
		component: () => import('@/components/Member.vue'),
		props: true,
	},
	{
		path: '/band/:id',
		name: 'Band',
		component: () => import('@/components/Band.vue'),
		props: true,
	},
	{
		path: '/veranstaltungen',
		name: 'Veranstaltungen',
		component: () => import('@/components/Events.vue')
	},
	{
		path: '/veranstaltungen/in/:location',
		name: 'VeranstaltungenLocation',
		component: () => import('@/components/Events.vue'),
		props: true,
	},
	{
		path: '/veranstaltungen/sind/:type',
		name: 'VeranstaltungenType',
		component: () => import('@/components/Events.vue'),
		props: true,
	},
	{
		path: '/veranstaltung/:id',
		name: 'Veranstaltung',
		component: () => import('@/components/Event.vue'),
		props: true,
	},
	{
		path: '/veranstaltungen/rueckblicke',
		name: 'Rueckblicke',
		component: () => import('@/views/EventReviews.vue'),
	},
	{
		path: '/beitrag/:id',
		name: 'Beitrag',
		component: () => import('@/components/Post.vue'),
		props: true,
	},
	{
		path: '/kontakt',
		name: 'Kontakt',
		component: () => import('@/views/Contact.vue')
	},
	{
		path: '/impressum',
		name: 'Impressum',
		component: () => import('@/views/Imprint.vue')
	},
	{
		path: '/spenden',
		name: 'Spenden',
		component: () => import('@/views/Donate.vue'),
	},
	{
		path: '/verein/mitglied-werden',
		name: 'Beitreten',
		component: () => import('@/views/Join.vue')
	},
	{
		path: '/datenschutz',
		name: 'Datenschutz',
		component: () => import('@/views/Datasecurity.vue')
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

export default router
