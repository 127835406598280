<template>
		<footer class="bg-black footer">
			<div class="footer-top">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-lg-3 my-4">
							<img src="/static/img/wappen.png" class="img-fluid" style="height:80px;">
							<div class="text-white-65 pt-4">
								Banater Schwaben Kreisverband Reutlingen e.V.<br/>
								1. Vorsitzende: Theresia Christine Neu<br/>
								Bruno-Matzke-Str. 10<br/>
								D-72770 Reutlingen<br/>
							</div>
						</div>
						<div class="col-sm-6 col-lg-2 my-4">
							<h5 class="text-white mb-5 fw-light text-uppercase">Verein</h5>
							<ul class="list-unstyled white-link footer-links">
								<li><router-link :to="{ name: 'VereinNews' }">Neuigkeiten</router-link></li>
								<li><router-link :to="{ name: 'VereinAbout'}">Über uns</router-link></li>
								<li><router-link :to="{ name: 'VereinMembers', params: { type: 'vorstand' } }">Vorstand</router-link></li>
								<li><router-link :to="{ name: 'VereinMembers', params: { type: 'beisitzer' } }">Erweiterter Vorstand</router-link></li>
							</ul>
						</div>
						<div class="col-sm-6 col-lg-2 my-4">
							<h5 class="text-white mb-5 fw-light text-uppercase">Arbeitsgruppen</h5>
							<ul class="list-unstyled white-link footer-links">
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'backakademie' } }">Backakademie</router-link></li>
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'banater-knipser' } }">Banater Knipser</router-link></li>
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'deko-basteln' } }">Deko &amp; Basteln</router-link></li>
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'theatergruppe' } }">Theatergruppe</router-link></li>
								<li><router-link :to="{ name: 'Arbeitsgruppe', params: { slug: 'trachtengruppe' } }">Trachtengruppe</router-link></li>
								
							</ul>
						</div>
						<div class="col-sm-6 col-lg-2 my-4">
							<h5 class="text-white mb-5 fw-light text-uppercase">Veranstaltungen</h5>
							<ul class="list-unstyled white-link footer-links">
								<li><router-link :to="{ name: 'Rueckblicke' }">Rückblicke</router-link></li>
								<li><router-link :to="{ name: 'Veranstaltungen'}">Alle Veranstaltungen</router-link></li>
								<li><router-link :to="{ name: 'VeranstaltungenLocation', params: { location: 'reutlingen' } }">in Reutlingen</router-link></li>
								<li><router-link :to="{ name: 'VeranstaltungenLocation', params: { location: 'deutschland' } }">in Deutschland</router-link></li>
								<li><router-link :to="{ name: 'VeranstaltungenLocation', params: { location: 'rumaenien' } }">in Rumänien</router-link></li>
							</ul>
						</div>
						<div class="col-sm-6 col-lg-2 my-4">
							<h5 class="text-white mb-5 fw-light text-uppercase">Rechtliches</h5>
							<ul class="list-unstyled white-link footer-links">
								<li><router-link :to="{ name: 'Kontakt' }">Kontakt</router-link></li>
								<li><router-link :to="{ name: 'Impressum' }">Impressum</router-link></li>
								<li><router-link :to="{ name: 'Datenschutz' }">Datenschutz</router-link></li>
								<li><router-link :to="{ name: 'Spenden' }">Spenden</router-link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-bottom border-style top light">
				<div class="container">
					<div class="row">
						<div class="col-md-6 my-3">
							<div class="nav justify-content-center justify-content-md-start">
								<a class="icon icon-sm icon-info rounded-circle me-2" href="https://de-de.facebook.com/Landsmannschaft-Banater-Schwaben-Kreisverband-Reutlingen-174918199228122/" target="_blank">
									<i class="fab fa-facebook-f"></i>
								</a>
								<a class="icon icon-sm icon-primary rounded-circle me-2" href="#">
									<i class="fab fa-youtube"></i>
								</a>
							</div>
						</div>
						<div class="col-md-6 my-3 text-center text-md-end text-white-80">
							<p class="m-0">&copy; 2009-2023 bs-rt.de. Made with <span class="fad fa-heart text-danger"></span> in Reutlingen. {23/02}</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
</template>