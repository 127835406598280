import { createStore } from 'vuex'
import api from 'axios'

export default createStore({
	state: {
		members: [],
		events: [],
		groups: [],
		posts: [],
		locations: [],
		bands: [],
		timeline: [],
		loadingStatus: true,
		errorStatus: false,
	},
	mutations: {
		SAVE_MEMBERS(state, members) {
			state.members = members;
		},
		SAVE_EVENTS(state, events) {
			state.events = events;
		},
		SAVE_GROUPS(state, groups) {
			state.groups = groups;
		},
		SAVE_POSTS(state, posts) {
			state.posts = posts;
		},
		SAVE_LOCATIONS(state, locations) {
			state.locations = locations;
		},
		loadingStatus (state, newLoadingStatus) {
			state.loadingStatus = newLoadingStatus;
		},
		errorStatus (state, newErrorStatus) {
			state.errorStatus = newErrorStatus;
		},
		SAVE_BANDS(state, bands) {
			state.bands = bands;
		},
		SAVE_TIMELINE(state, timeline) {
			state.timeline = timeline;
		}

	},
	actions: {
		loadMembers({commit}) {
			api.get('items/member?sort=name&fields[]=*,group1.slug,group2.slug,group1.post.*,group2.post.*,picture.id')
			.then(result => {
				commit('SAVE_MEMBERS', result.data.data);
				commit('loadingStatus', false);
			})
			.catch(error => {
				console.log(error);
				commit('errorStatus', true);
			});
		},
		loadEvents({commit}) {
			api.get('items/event?sort=date_start&filter[status][_eq]=published&fields[]=*,post.*,bandname.*,bandname.post.*,location.*')
			.then(result => {
				commit('SAVE_EVENTS', result.data.data);
				commit('loadingStatus', false);
			})
			.catch(error => {
				console.log(error);
				commit('errorStatus', true);
			});
		},
		loadGroups({commit}) {
			api.get('items/groups?fields[]=*,leaders.*.*,post.*,subpost.*')
			.then(result => {
				commit('SAVE_GROUPS', result.data.data);
				commit('loadingStatus', false);
			})
			.catch(error => {
				commit('errorStatus', true);
				console.log(error);
			});
		},
		loadPosts({commit}) {
			api.get('items/post?sort=date_created&filter[status][_eq]=published&fields[]=*,creator.*,gallery.*.*,video.*,picture.*')
			.then(result => {
				commit('SAVE_POSTS', result.data.data);
				commit('loadingStatus', false);
			})
			.catch(error => {
				console.log(error);
				commit('errorStatus', true);
			});
		},
		loadLocations({commit}) {
			api.get('items/location')
			.then(result => {
				commit('SAVE_LOCATIONS', result.data.data);
				commit('loadingStatus', false);
			})
			.catch(error => {
				console.log(error);
				commit('errorStatus', true);
			});
		},
		loadBands({commit}) {
			api.get('items/band?fields[]=*,post.*')
			.then(result => {
				commit('SAVE_BANDS', result.data.data);
				commit('loadingStatus', false);
			})
			.catch(error => {
				console.log(error);
				commit('errorStatus', true);
			});
		},
		loadTimeline({commit}) {
			api.get('items/timeline?sort=year&filter[status][_eq]=published')
			.then(result => {
				commit('SAVE_TIMELINE', result.data.data);
				commit('loadingStatus', false);
			})
			.catch(error => {
				console.log(error);
				commit('errorStatus', true);
			});
		}
	},
	getters: {
		loadingStatus (state) {
			return state.loadingStatus;
		},
		errorStatus (state) {
			return state.errorStatus;
		}
	},
	modules: {
	}
})
